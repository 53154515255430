@import "https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap";
.tada:hover {
  -webkit-animation: var(--animation-speed-slow) infinite ease tada;
  animation: var(--animation-speed-slow) infinite ease tada;
}

.logo-spin {
  animation: 5s linear infinite logo-spin;
}

@keyframes handWaveAnimation {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(14deg);
  }

  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@-webkit-keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.95, .95, .95)rotate3d(0, 0, 1, -10deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1, 1, 1)rotate3d(0, 0, 1, 10deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1, 1, 1)rotate3d(0, 0, 1, -10deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.95, .95, .95)rotate3d(0, 0, 1, -10deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1, 1, 1)rotate3d(0, 0, 1, 10deg);
  }

  40%, 60%, 80% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --clr-body-background: #fcfcfd;
  --clr-text-200: #878792;
  --clr-text-300: #55555e;
  --clr-text-400: #333338;
  --clr-title-text: var(--clr-text-300);
  --clr-about__text: var(--clr-text-400);
  --clr-gray-50: #d4d4d4;
  --clr-gray-100: #d6d6d6;
  --clr-gray-200: #a3a3a3;
  --clr-gray-300: var(--clr-text-300);
  --clr-primary: #64b2ce;
  --clr-secondary: #3d9ec2;
  --clr-nav: #ebf0fbbf;
  --clr-nav--scrolled: #00000021;
  --clr-nav--border: var(--clr-gray-50);
  --clr-plain-bg: #fff;
  --clr-card-bg: #ebf0fbb3;
  --clr-card-title: var(--clr-gray-300);
  --clr-card-title--hover: var(--clr-text-400);
  --clr-card-body: var(--clr-text-200);
  --clr-section-background-text: #d4d4d466;
  --clr-accent: #f6bf31;
  --clr-shape-1: linear-gradient(90deg, #0062ff0a 0%, #61efff0a 100%);
  --clr-shape-2: linear-gradient(45deg, #f4e5f54d 45%, #6a76f64d 100%);
  --clr-shape-3: #e2f8e980;
  --clr-shape-4: linear-gradient(45deg, #3592e333 0%, #17e8a933 100%);
  --clr-shape-5: linear-gradient(45deg, #f490f133 0%, #f9c48b33 100%);
  --clr-shape-6: linear-gradient(45deg, #ff930f33 0%, #fffa5c33 100%);
}

.dark-mode {
  --clr-body-background: #0b121d;
  --clr-nav: #0f1829bf;
  --clr-nav--scrolled: #ffffff21;
  --clr-nav--border: #212d40;
  --clr-plain-bg: #192943;
  --clr-card-bg: #0f1829bf;
  --clr-title-text: #cbd6f6;
  --clr-text-200: #78786d;
  --clr-text-300: #aaaaa1;
  --clr-text-400: #ccccc7;
  --clr-card-title: #e7e7e4;
  --clr-card-title--hover: #42423d;
  --clr-card-body: #e7e7e4;
  --clr-section-background-text: #d4d4d414;
  --clr-about__text: var(--clr-text-400);
  --clr-shape-1: #2d140680;
  --clr-shape-2: linear-gradient(90deg, #61097780 0%, #00ffee80 100%);
  --clr-shape-3: linear-gradient(45deg, #00ff884d 0%, #61efff4d 100%);
  --clr-shape-4: linear-gradient(45deg, #3592e333 0%, #17e8a933 100%);
  --clr-shape-5: linear-gradient(45deg, #f490f133 0%, #f9c48b33 100%);
  --clr-shape-6: linear-gradient(45deg, #ff930f1a 0%, #fffa5c1a 100%);
  --clr-shape-shadow-1: #1b9ae480;
  --clr-shape-shadow-2: #b447b833;
  --clr-shape-shadow-3: #33cc6333;
  --clr-shape-shadow-4: #b3b34d;
  --clr-shape-shadow-6: #1b9ae41a;
}

:root {
  --ff-default: "Manrope", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --ff-title: "Epilogue", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --ff-section-bg-text: "Montserrat", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --fs-100: .625rem;
  --fs-110: .875rem;
  --fs-200: 1rem;
  --fs-300: 1.125rem;
  --fs-400: 1.25rem;
  --fs-410: 1.375rem;
  --fs-500: 1.625rem;
  --fs-550: 2.188rem;
  --fs-600: 2.5rem;
  --fs-700: 3.125rem;
  --fs-800: 3.75rem;
  --fs-900: 5rem;
}

*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  *, :before, :after {
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

.container--main {
  width: 90%;
  max-width: 1200px;
  margin-inline: auto;
  position: relative;
}

@media (width >= 1000px) {
  .container--main {
    width: 80%;
    max-width: 1000px;
  }
}

.container--inner {
  width: 86%;
  max-width: 800px;
  margin-inline: auto;
}

@media (width >= 800px) {
  .container--inner {
    width: 100%;
    max-width: 1000px;
  }
}

.btn {
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: none;
  position: relative;
}

.btn--solid {
  color: #fff;
  font-weight: 500;
  font-size: var(--fs-300);
  border-radius: var(--border-radius-200);
  background-color: var(--clr-primary);
  transition: background-color var(--transition-speed-normal) .1s cubic-bezier(.55, 0, .1, 1);
  padding: 1em .75em;
  position: relative;
  overflow: hidden;
}

.btn--solid:focus, .btn--solid:hover {
  background-color: var(--clr-accent);
}

.btn--solid:before, .btn--solid:after {
  content: "";
  opacity: .8;
  pointer-events: none;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
}

.btn--solid:before {
  width: 100%;
  height: 100%;
  transition: left var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1), transform var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1), background-color var(--transition-speed-normal) .1s cubic-bezier(.55, 0, .1, 1);
  background-color: #0000001a;
  bottom: 0;
  left: 100%;
  transform: translateX(-50%)translateY(100%)rotate(-25deg);
}

.btn--solid:focus:before, .btn--solid:hover:before {
  background-color: var(--clr-accent);
  left: 0;
  transform: translateX(0)translateY(0)scale3d(1.4, 1.4, 1);
}

.btn--solid:after {
  width: 140%;
  height: 100%;
  background-color: var(--clr-accent);
  transition: left var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1), transform var(--transition-speed-normal) 25ms cubic-bezier(.55, 0, .1, 1);
  bottom: 0;
  left: 100%;
  transform: translateX(-50%)translateY(140%)rotate(-25deg);
}

.btn--solid:focus:after, .btn--solid:hover:after {
  left: 0%;
  transform: translateX(0%)translateY(0%)scale3d(2, 2, 1);
}

.btn--icon-only {
  justify-content: center;
  align-items: center;
  display: flex;
}

.logo {
  font-weight: 600;
  font-size: var(--fs-400);
  color: var(--clr-primary);
  transition: background-color var(--transition-speed-normal) ease;
  border-radius: var(--border-radius-200);
  transition: color var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1);
  isolation: isolate;
  padding: 0 .5em;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.logo:focus, .logo:hover {
  color: #fff;
}

.logo:before, .logo:after {
  content: "";
  opacity: .8;
  pointer-events: none;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
}

.logo:before {
  width: 100%;
  height: 100%;
  transition: transform var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1), background-color var(--transition-speed-normal) .1s cubic-bezier(.55, 0, .1, 1);
  background-color: #0000001a;
  inset: 0;
  transform: translateY(100%);
}

.logo:focus:before, .logo:hover:before {
  background-color: var(--clr-primary);
  transform: translateY(0)scale3d(1.4, 1.4, 1);
}

.logo:after {
  width: 140%;
  height: 100%;
  background-color: var(--clr-primary);
  transition: transform var(--transition-speed-normal) 25ms cubic-bezier(.55, 0, .1, 1);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)translateY(180%);
}

.logo:focus:after, .logo:hover:after {
  transform: translateX(-50%)translateY(0%)scale3d(1.6, 1.6, 1);
}

.link {
  color: var(--clr-primary);
  font-weight: 500;
  font-size: var(--fs-400);
  border-radius: var(--border-radius-200);
  transition: color var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1);
  isolation: isolate;
  padding: 0 .5em;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.link:focus, .link:hover {
  color: #fff;
}

.link:before, .link:after {
  content: "";
  opacity: .8;
  pointer-events: none;
  z-index: -1;
  border-radius: 50%;
  position: absolute;
}

.link:before {
  width: 100%;
  height: 100%;
  transition: transform var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1), background-color var(--transition-speed-normal) .1s cubic-bezier(.55, 0, .1, 1);
  background-color: #0000001a;
  inset: 0;
  transform: translateY(100%);
}

.link:focus:before, .link:hover:before {
  background-color: var(--clr-primary);
  transform: translateY(0)scale3d(1.4, 1.4, 1);
}

.link:after {
  width: 140%;
  height: 100%;
  background-color: var(--clr-primary);
  transition: transform var(--transition-speed-normal) 25ms cubic-bezier(.55, 0, .1, 1);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%)translateY(180%);
}

.link:focus:after, .link:hover:after {
  transform: translateX(-50%)translateY(0%)scale3d(1.6, 1.6, 1);
}

.link--lrg {
  font-size: var(--fs-400);
}

.socials {
  border: 1px solid var(--clr-gray-100);
  border-radius: var(--border-radius-400);
  justify-content: space-evenly;
  align-items: center;
  margin-block: 1.3em;
  padding: .5em 1em;
  display: flex;
}

.socials__icon {
  fill: var(--clr-text-300);
  width: var(--fs-500);
  height: var(--fs-500);
  transition: fill var(--transition-speed-normal) ease;
  margin: 0;
  padding: 0;
}

.socials__icon[data-name="linkedin-square"]:hover {
  fill: #0b66c1;
}

.socials__icon[data-name="github"]:hover {
  fill: #7741c8;
}

.socials__icon[data-name="gmail"]:hover {
  fill: #ea4034;
}

.socials__icon[data-name="instagram"]:hover {
  fill: #dd2c73;
}

body.dark-mode .shape {
  -webkit-filter: blur(10px) saturate(1.3);
  filter: blur(10px) saturate(1.3);
}

body.dark-mode .shape-1 {
  display: none;
}

body.dark-mode .shape-2 {
  box-shadow: 0 0 50px 1px var(--clr-shape-shadow-2);
  transition: box-shadow var(--transition-speed-normal) ease;
  top: 10%;
  right: 40%;
}

body.dark-mode .shape-3 {
  box-shadow: 0 0 50px 1px var(--clr-shape-shadow-3);
  display: unset;
  width: 160px;
  height: 160px;
  transition: box-shadow var(--transition-speed-normal) ease;
  bottom: 30%;
  right: 10%;
}

@media (width >= 800px) {
  body.dark-mode .shape-3 {
    width: 200px;
    height: 200px;
  }
}

@media (width >= 1200px) {
  body.dark-mode .shape-3 {
    right: 16%;
  }
}

@media (width >= 1400px) {
  body.dark-mode .shape-3 {
    right: 20%;
  }
}

.shape {
  font-size: var(--fs-500);
  -webkit-filter: blur(10px);
  filter: blur(10px);
  pointer-events: none;
  width: 50px;
  height: 300px;
  border-radius: var(--border-radius-500);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.shape-1 {
  background: var(--clr-shape-1);
  height: 800px;
  width: 200px;
  border-radius: var(--border-radius-600);
  transition: background var(--transition-speed-normal) ease;
  top: 20%;
  left: 25%;
  transform: scale(1)rotate(-45deg);
}

.shape-2 {
  background: var(--clr-shape-2);
  width: 100px;
  height: 100px;
  transition: background var(--transition-speed-normal) ease;
  top: 10%;
  right: 30%;
  transform: scale(1)rotate(-45deg);
}

@media (width >= 800px) {
  .shape-2 {
    width: 200px;
    height: 200px;
    top: 25%;
    right: 10%;
  }
}

@media (width >= 1000px) {
  .shape-2 {
    width: 200px;
    height: 200px;
    top: 20%;
    right: 10%;
  }
}

@media (width >= 1200px) {
  .shape-2 {
    width: 200px;
    height: 200px;
    top: 10%;
    right: 20%;
  }
}

@media (width >= 1400px) {
  .shape-2 {
    width: 200px;
    height: 200px;
    top: 18%;
    right: 30%;
  }
}

.shape-3 {
  background: var(--clr-shape-3);
  width: 200px;
  height: 200px;
  transition: background var(--transition-speed-normal) ease;
  display: none;
  bottom: 20%;
  right: 10%;
  transform: scale(1)rotate(45deg);
}

.shape-4 {
  background: var(--clr-shape-4);
  width: 200px;
  height: 200px;
  transition: background var(--transition-speed-normal) ease;
  top: 30%;
  right: 20%;
  transform: scale(1)rotate(45deg);
}

.shape-5 {
  background: var(--clr-shape-5);
  width: 100px;
  height: 100px;
  transition: background var(--transition-speed-normal) ease;
  bottom: 0%;
  left: 20%;
  transform: scale(1)rotate(45deg);
}

.shape-6 {
  background: var(--clr-shape-6);
  width: 40px;
  height: 140px;
  transition: background var(--transition-speed-normal) ease;
  bottom: 0%;
  right: 20%;
  transform: scale(1)rotate(45deg);
}

@media (width >= 800px) {
  .shape-6 {
    width: 100px;
    height: 240px;
  }
}

.spacer {
  width: 100%;
  height: 50vh;
  border: 5px solid red;
}

.spacer.scroll-down {
  z-index: 100;
}

.projects .card.work-in-progress {
  position: relative;
}

.projects .card.work-in-progress:after {
  content: "Work in progress";
  color: var(--clr-text-400);
  background-color: var(--clr-plain-bg);
  width: max-content;
  height: max-content;
  border-radius: var(--border-radius-100);
  transition: color var(--transition-speed-normal) ease, background-color var(--transition-speed-normal) ease;
  padding: .8em .5em;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
}

.projects .card.work-in-progress .card__link {
  cursor: not-allowed;
  opacity: .6;
  pointer-events: none;
}

.skill {
  background-color: var(--clr-plain-bg);
  border-radius: var(--border-radius-500);
  transition: transform var(--transition-speed-normal) ease, border var(--transition-speed-normal) ease, background-color var(--transition-speed-normal) ease;
  border: 1px solid #d6d6d6;
  justify-content: space-evenly;
  align-items: center;
  gap: .3em;
  padding: .4em 1em;
  display: flex;
}

.skill:hover {
  transform: scale(1.02);
}

.skill:hover .skill__text {
  color: var(--clr-card-title--hover);
}

.skill:hover .skill__icon {
  fill: var(--clr-card-title--hover);
}

.skill[data-name="reactjs"]:hover {
  background-color: #e6fafe;
  border: 1px solid #60dcfb;
}

.skill[data-name="tailwindcss"]:hover {
  background-color: #e6fbfe;
  border: 1px solid #07b6d5;
}

.skill[data-name="styled-components"]:hover {
  background-color: #faebf0;
  border: 1px solid #db7094;
}

.skill[data-name="postgresql"]:hover {
  background-color: #e9eefc;
  border: 1px solid #4169e1;
}

.skill[data-name="html5"]:hover {
  background-color: #fdeee7;
  border: 1px solid #f16427;
}

.skill[data-name="css3"]:hover {
  background-color: #e7eefd;
  border: 1px solid #0e4ad8;
}

.skill[data-name="sass"]:hover {
  background-color: #f9ecf2;
  border: 1px solid #d279a4;
}

.skill[data-name="bootstrap"]:hover {
  background-color: #fbe6fe;
  border: 1px solid #6f29fa;
}

.skill[data-name="javascript"]:hover {
  background-color: #ffffe6;
  border: 1px solid #ffff00b3;
}

.skill[data-name="nodejs"]:hover {
  background-color: #edf7ed;
  border: 1px solid #036f01;
}

.skill[data-name="golang"]:hover {
  background-color: #e6faff;
  border: 1px solid #00abd6;
}

.skill[data-name="mongodb"]:hover {
  background-color: #eef8ec;
  border: 1px solid #12644b;
}

.skill[data-name="mongoose"]:hover {
  background-color: #ffe6e6;
  border: 1px solid #8a0000;
}

.skill[data-name="expressjs"]:hover {
  background-color: #e6f4ff;
  border: 1px solid #007ee6;
}

.skill[data-name="ejs"]:hover {
  background-color: #f6f8ec;
  border: 1px solid #b3ca63;
}

.skill[data-name="gsap3"]:hover {
  background-color: #f1fce9;
  border: 1px solid #57aa18;
}

.skill[data-name="git"]:hover {
  background-color: #fdebe7;
  border: 1px solid #f04f33;
}

.skill[data-name="figma"]:hover {
  background-color: #e6e6ff;
  border: 1px solid #5452ff;
}

.skill[data-name="photoshop"]:hover {
  background-color: #e6f3ff;
  border: 1px solid #011f37;
}

.skill[data-name="googlesheets"]:hover {
  background-color: #eafbee;
  border: 1px solid #178239;
}

.skill[data-name="batchScripting"]:hover {
  background-color: #e6f4ff;
  border: 1px solid #0076d1;
}

.skill__icon {
  width: 23px;
  height: 33px;
  fill: var(--clr-card-title);
  transition: fill var(--transition-speed-normal) ease;
}

.skill__icon[data-name="reactjs"]:hover {
  fill: #60dcfb;
}

.skill__icon[data-name="tailwindcss"]:hover {
  fill: #07b6d5;
}

.skill__icon[data-name="styled-components"]:hover {
  fill: #db7094;
}

.skill__icon[data-name="postgresql"]:hover {
  fill: #4169e1;
}

.skill__icon[data-name="html5"]:hover {
  fill: #f16427;
}

.skill__icon[data-name="css3"]:hover {
  fill: #0e4ad8;
}

.skill__icon[data-name="sass"]:hover {
  fill: #d279a4;
}

.skill__icon[data-name="bootstrap"]:hover {
  fill: #6f29fa;
}

.skill__icon[data-name="javascript"]:hover {
  fill: #cc3;
}

.skill__icon[data-name="nodejs"]:hover {
  fill: #036f01;
}

.skill__icon[data-name="golang"]:hover {
  fill: #00abd6;
}

.skill__icon[data-name="mongodb"]:hover {
  fill: #12644b;
}

.skill__icon[data-name="expressjs"]:hover {
  fill: #007ee6;
}

.skill__icon[data-name="git"]:hover {
  fill: #f04f33;
}

.skill__icon[data-name="figma"]:hover {
  fill: #5452ff;
}

.skill__icon[data-name="mongoose"]:hover {
  fill: #8a0000;
}

.skill__icon[data-name="ejs"]:hover {
  fill: #b3ca63;
}

.skill__icon[data-name="gsap3"]:hover {
  fill: #57aa18;
}

.skill__icon[data-name="photoshop"] {
  width: 20px;
  height: 30px;
}

.skill__icon[data-name="photoshop"]:hover {
  fill: #011f37;
}

.skill__icon[data-name="googlesheets"] {
  width: 20px;
  height: 30px;
}

.skill__icon[data-name="googlesheets"]:hover {
  fill: #178239;
}

.skill__icon[data-name="batchScripting"]:hover {
  fill: #0076d1;
}

.skill__text {
  color: var(--clr-card-title);
  transition: color var(--transition-speed-normal) ease;
  font-weight: 500;
}

:root {
  --border-radius-100: .188rem;
  --border-radius-200: .5rem;
  --border-radius-300: .625rem;
  --border-radius-400: 1.25rem;
  --border-radius-500: 1.875rem;
  --border-radius-600: 3.125rem;
  --transition-speed-normal: .5s;
  --transition-timing-function-cubic: cubic-bezier(.55, 0, .1, 1);
  --animation-speed-slow: 1.6s;
}

:focus-visible {
  border: 2px dashed var(--clr-accent);
  outline: 1px dashed var(--clr-accent);
}

::-webkit-scrollbar {
  width: var(--fs-100);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 5px #0006;
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary);
  border-radius: var(--border-radius-300);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--clr-secondary);
}

body {
  font-family: var(--ff-default);
  color: var(--clr-text-300);
  background-color: var(--clr-body-background);
  min-height: 100vh;
  transition: background-color var(--transition-speed-normal) ease, color var(--transition-speed-normal) ease;
  font-weight: 400;
}

.nav {
  border: .5px solid var(--clr-nav--border);
  border-radius: var(--border-radius-600);
  width: 90%;
  max-width: 1200px;
  height: max-content;
  z-index: 4;
  background-color: var(--clr-nav);
  -webkit-backdrop-filter: blur(10px) saturate(1.4);
  backdrop-filter: blur(10px) saturate(1.4);
  transition: box-shadow var(--transition-speed-normal) ease, background-color var(--transition-speed-normal) ease, border var(--transition-speed-normal) ease;
  justify-content: space-between;
  align-items: center;
  margin-block: 1em;
  padding: 1em;
  display: flex;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 1000px) {
  .nav {
    width: 80%;
    max-width: 1000px;
  }
}

.nav.scrolled {
  box-shadow: 0 0 20px 2px var(--clr-nav--scrolled);
}

.nav__item__container--left {
  width: 100%;
  align-items: center;
  display: flex;
}

@media (width >= 800px) {
  .nav__item__container--left {
    width: 50%;
  }

  .nav #hamburger--open {
    display: none;
  }
}

.nav__item__container--right[data-type="mobile"] {
  border: 1px dashed var(--clr-secondary);
  width: 100%;
  min-height: 87vh;
  border-radius: var(--border-radius-400);
  background-color: var(--clr-nav);
  z-index: 1;
  visibility: hidden;
  position: fixed;
  top: 0%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

.nav__item__container--right[data-type="mobile"]:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--clr-nav);
  -webkit-backdrop-filter: blur(10px) saturate(1.4);
  -webkit-backdrop-filter: blur(10px) saturate(1.4);
  backdrop-filter: blur(10px) saturate(1.4);
  z-index: -1;
  position: absolute;
}

@media (width >= 800px) {
  .nav__item__container--right[data-type="mobile"] {
    display: none;
  }
}

.nav__item__container--right[data-type="mobile"] .hamburger--close-container {
  justify-content: flex-end;
  padding: 1em;
  display: flex;
}

@media (width >= 800px) {
  .nav__item__container--right[data-type="mobile"] .hamburger--close-container, .nav__item__container--right[data-type="mobile"] #hamburger--close {
    display: none;
  }
}

.nav__item__container--right[data-type="mobile"] .close-icon {
  width: 40px;
  height: 40px;
}

.nav__item__container--right[data-type="mobile"] .nav__list {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .8em;
  margin: 0;
  padding: 2em;
  list-style: none;
  display: flex;
}

.nav__item__container--right[data-type="desktop"] {
  z-index: 1;
  display: none;
}

@media (width >= 800px) {
  .nav__item__container--right[data-type="desktop"] {
    width: 50%;
    display: block;
  }
}

.nav__item__container--right[data-type="desktop"] .nav__list {
  height: auto;
  justify-content: flex-end;
  align-items: center;
  gap: .8em;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav__link {
  font-size: var(--fs-600);
  color: var(--clr-text-400);
  transition: color var(--transition-speed-normal) ease;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

@media (width >= 800px) {
  .nav__link {
    font-size: var(--fs-300);
    border-radius: var(--border-radius-100);
    font-weight: 500;
  }
}

.nav .dark-mode-icon, .nav .light-mode-icon, .nav .hamburger-icon, .nav .close-icon {
  fill: var(--clr-primary);
}

@media (width >= 800px) {
  .nav .dark-mode-button[data-type="mobile"] {
    display: none;
  }
}

.nav .dark-mode-button[data-type="desktop"] {
  display: none;
}

@media (width >= 800px) {
  .nav .dark-mode-button[data-type="desktop"] {
    display: block;
  }
}

.nav .dark-mode-button[data-type="mobile"], .nav .dark-mode-button[data-type="desktop"] {
  width: 30px;
  height: 30px;
  overflow: hidden;
}

.nav .dark-mode-button[data-type="mobile"] .dark-mode-icon--container, .nav .dark-mode-button[data-type="mobile"] .light-mode-icon--container, .nav .dark-mode-button[data-type="desktop"] .dark-mode-icon--container, .nav .dark-mode-button[data-type="desktop"] .light-mode-icon--container {
  width: 100%;
  height: 100%;
  transition: transform var(--transition-speed-normal) cubic-bezier(.55, 0, .1, 1);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nav .dark-mode-button[data-type="mobile"] .dark-mode-icon--container, .nav .dark-mode-button[data-type="desktop"] .dark-mode-icon--container {
  transform: translate(0, -100%);
}

.hero {
  min-height: 87vh;
  min-height: 87dvh;
  align-items: center;
  gap: 1em;
  margin-block-start: 13vh;
  display: grid;
  position: relative;
  overflow: hidden;
}

@media (width >= 800px) {
  .hero {
    min-height: 100vh;
    min-height: 100dvh;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    margin-block-start: 0;
  }
}

.hero__left {
  text-align: center;
  padding-inline: 1em;
}

@media (width >= 600px) {
  .hero__left {
    padding-inline: 3em;
  }
}

@media (width >= 800px) {
  .hero__left {
    text-align: initial;
    flex-direction: column;
    padding-inline: 0;
    display: flex;
  }
}

.hero__left .hero__greet {
  color: var(--clr-primary);
  font-size: var(--fs-400);
  margin-block-end: 1em;
  font-weight: 500;
}

.hero__left .hero__title {
  font-family: var(--ff-title);
  color: var(--clr-title-text);
  font-size: var(--fs-600);
  transition: color var(--transition-speed-normal) ease;
  line-height: 1.1;
}

@media (width >= 600px) {
  .hero__left .hero__title {
    font-size: var(--fs-600);
  }
}

@media (width >= 800px) {
  .hero__left .hero__title {
    font-size: var(--fs-700);
  }
}

.hero__left .hand-wave {
  transform-origin: 70% 70%;
  animation: 2.5s infinite handWaveAnimation;
  display: inline-block;
}

.hero__left .hero__subtitle {
  color: var(--clr-text-300);
  font-size: var(--fs-300);
  transition: color var(--transition-speed-normal) ease;
  margin-block-start: 1em;
}

@media (width >= 600px) {
  .hero__left .hero__subtitle {
    font-size: var(--fs-300);
  }
}

.hero__left--upper {
  margin-block-start: 3em;
}

@media (width >= 800px) {
  .hero__left--upper {
    min-height: 300px;
    margin-block-start: 0;
  }
}

.hero__left--lower {
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-block: 2em;
  display: flex;
}

@media (width >= 800px) {
  .hero__left--lower {
    flex-direction: row;
    margin-block-start: 2.2em;
  }
}

@media (width >= 1000px) {
  .hero__left--lower {
    flex-direction: row;
    margin-block-start: 2em;
  }
}

.hero__left .btn {
  width: 50%;
  padding: .5em;
}

@media (width >= 800px) {
  .hero__left .btn {
    width: max-content;
    padding: .3em 1.3em;
  }
}

.hero__left .btn, .hero__left .link--lrg {
  font-size: var(--fs-300);
  padding: .5em;
}

@media (width >= 800px) {
  .hero__left .btn, .hero__left .link--lrg {
    width: max-content;
    padding: .3em 1.3em;
  }
}

@media (width >= 1000px) {
  .hero__left .btn, .hero__left .link--lrg {
    font-size: var(--fs-400);
    padding: .5em 1.4em;
  }
}

@media (width >= 800px) {
  .hero__right--upper {
    margin-block-start: 3em;
  }
}

@media (width >= 1000px) {
  .hero__right--upper {
    margin-block-start: 0;
  }
}

.hero__right .hero__img-box {
  isolation: isolate;
  border: 1px solid var(--clr-gray-200);
  border-radius: var(--border-radius-400);
  max-width: 300px;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
}

.hero__right .hero__img-box .hero__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  inset: 0;
  transform: scale(1.05);
}

.hero__right .hero__img-box .hero__img {
  transform: scale(1.3)translate(1%, 6%);
}

.hero__right .socials {
  width: 100%;
  margin-inline: auto;
}

@media (width >= 600px) {
  .hero__right .socials {
    width: 65%;
  }
}

@media (width >= 800px) {
  .hero__right .socials {
    width: 90%;
  }
}

@media (width >= 1000px) {
  .hero__right .socials {
    width: 80%;
  }
}

.hero__bottom {
  width: 100%;
  flex-direction: column;
  grid-column: 1 / -1;
  align-items: center;
  gap: 0;
  display: flex;
}

@media (width >= 800px) {
  .hero__bottom {
    gap: .2em;
    position: absolute;
    bottom: 5%;
    left: 0;
  }
}

.hero__bottom--text p {
  font-family: var(--ff-title);
  font-weight: 500;
  font-size: var(--fs-200);
  color: var(--clr-text-300);
  text-transform: uppercase;
  letter-spacing: 4px;
}

.hero__bottom .mouse-svg {
  width: 70px;
  height: 70px;
  fill: none;
}

.hero__bottom .mouse-svg #body {
  stroke: var(--clr-text-300);
}

.hero__bottom .mouse-svg #scroll-wheel {
  fill: var(--clr-text-300);
}

section:not(.section__hero):not(.about) {
  margin-block: 5em;
}

@media (width >= 1000px) {
  section:not(.section__hero):not(.about) {
    width: 100%;
    max-width: 1000px;
    margin-inline: auto;
  }
}

section:not(.section__hero):not(.about):nth-of-type(2) {
  margin-block-start: 0;
}

.section__hero {
  position: relative;
  overflow: hidden;
}

.section__header {
  justify-content: space-between;
  align-items: center;
  margin-block-end: 1em;
  display: flex;
}

.section__title {
  color: var(--clr-primary);
}

@media (width >= 800px) {
  .section__title--normal {
    font-size: var(--fs-550);
  }
}

@media (width >= 1000px) {
  .section__title--normal {
    font-size: var(--fs-550);
  }

  .section__title--sm {
    font-size: var(--fs-300);
  }
}

.section__background-text {
  font-family: var(--ff-section-bg-text);
  color: var(--clr-section-background-text);
  font-size: var(--fs-900);
  transition: color var(--transition-speed-normal) ease;
  position: absolute;
  transform: translate(-1%, -5%);
}

.about {
  width: 100%;
  min-height: 100vh;
  isolation: isolate;
  z-index: 5;
  transition: background-color var(--transition-speed-normal) ease, color var(--transition-speed-normal) ease;
  position: relative;
  overflow: hidden;
}

.about__intro {
  isolation: isolate;
  height: 100vh;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.about .greeting--container {
  width: 100%;
  grid-area: 1 / 1 / -1 / -1;
  align-self: center;
}

.about .greeting {
  font-family: var(--ff-title);
  font-size: var(--fs-600);
  color: #fff;
  text-align: center;
}

.about .circle__container {
  z-index: -1;
  grid-area: 1 / 1 / -1 / -1;
  align-self: center;
}

.about .circle__container .circle {
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, #000 0%, #5743b6 100%);
  border-radius: 50%;
  margin-inline: auto;
  transform: scale(1);
}

@media (width >= 600px) {
  .about .circle__container .circle {
    width: 400px;
    height: 400px;
  }
}

@media (width >= 800px) {
  .about .circle__container .circle {
    width: 500px;
    height: 500px;
  }
}

.about__main {
  transition: background-color var(--transition-speed-normal) ease, color var(--transition-speed-normal) ease;
  background-color: #000;
  padding: 1em;
}

@media (width >= 1000px) {
  .about__main {
    justify-content: space-between;
    gap: 3em;
    display: flex;
  }
}

.about__img--container {
  margin-block: 3em;
}

.about__img--container .about__img {
  border-radius: var(--border-radius-300);
  object-fit: cover;
  max-width: 400px;
  width: 100%;
  pointer-events: none;
  margin-inline: auto;
}

@media (width >= 800px) {
  .about__img--container .about__img {
    max-width: 500px;
  }
}

@media (width >= 1000px) {
  .about__img--container .about__img {
    max-width: 600px;
  }
}

.about__text--container {
  transition: color var(--transition-speed-normal) ease;
  width: 90%;
  margin-inline: auto;
}

@media (width >= 1000px) {
  .about__text--container {
    margin-block: 3em;
  }
}

.about__text--container ul li:not(:last-child) {
  margin-block-end: 1.3em;
}

@media (width >= 1000px) {
  .about__text--container ul li:not(:last-child) {
    margin-block-end: 1em;
  }
}

.about .work-experience, .about .about-me {
  font-size: var(--fs-300);
  margin: 1em 0;
  line-height: 2;
}

.about .work-experience {
  margin-block-end: 1em;
}

.about .highlight {
  border-radius: var(--border-radius-100);
  transition: background-size var(--transition-speed-normal) var(--transition-timing-function-cubic), color var(--transition-speed-normal) var(--transition-timing-function-cubic), font-weight var(--transition-speed-normal) var(--transition-timing-function-cubic);
  background: linear-gradient(45deg, #3592e3 0%, #17e8a9 100%) 0 0 / 0% 100% no-repeat;
}

.about .highlight.active {
  color: #000;
  background-size: 100% 100%;
}

.projects {
  position: relative;
}

.projects .btn {
  color: #fff;
  font-size: var(--fs-200);
  border-radius: var(--border-radius-200);
  z-index: 3;
  justify-content: space-evenly;
  align-items: center;
  gap: .7em;
  padding: .5em 1em;
  text-decoration: none;
  display: flex;
}

.projects .pdf-icon {
  fill: #fff;
  transition: fill var(--transition-speed-normal) ease;
}

.projects__cards-container {
  flex-direction: column;
  gap: 2em;
  margin-block-end: 2em;
  display: flex;
}

.projects .card {
  background-color: var(--clr-card-bg);
  -webkit-backdrop-filter: blur(10px) saturate(1.4);
  backdrop-filter: blur(10px) saturate(1.4);
  border-radius: var(--border-radius-300);
  transition: background-color var(--transition-speed-normal) ease;
  padding: 2em;
}

.projects .card__sm {
  background-color: #0000;
  padding: 0;
}

.projects .card__title {
  color: var(--clr-card-title);
  transition: color var(--transition-speed-normal) ease;
}

@media (width >= 1000px) {
  .projects .card__title {
    font-size: var(--fs-400);
  }
}

.projects .card__body {
  color: var(--clr-card-body);
  transition: color var(--transition-speed-normal) ease;
  margin-block: 1em 2em;
  font-weight: 200;
  line-height: 1.8;
}

@media (width >= 1000px) {
  .projects .card__body {
    font-size: var(--fs-200);
  }
}

.projects .card__tech-used {
  width: 100%;
  flex-wrap: wrap;
  gap: .9em;
  display: flex;
}

@media (width >= 600px) {
  .projects .card__tech-used {
    width: 90%;
  }
}

.projects .card__tech-used .skill {
  border: 1px solid #d6d6d699;
  padding: .2em .8em;
}

.projects .card__tech-used .skill__icon {
  width: 18px;
  height: 28px;
}

.projects .card__tech-used .skill__text {
  font-size: var(--fs-110);
  color: var(--clr-primary);
}

.projects .card__link {
  font-size: var(--fs-200);
  justify-content: flex-end;
  margin-block-start: 1em;
  display: flex;
}

.projects .card--transparent {
  margin-block-end: 2em;
}

.projects .more-projects {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.projects .more-projects ul {
  margin: 0;
  padding: 0 2em;
  list-style-type: circle;
}

.projects .more-projects li:not(li:first-of-type) {
  margin-block: 1em;
}

.projects .more-projects .link {
  display: inline-flex;
}

.projects .link {
  font-size: var(--fs-200);
}

.projects .link[data-name="frontendmentor"] {
  top: 8px;
}

.skills {
  position: relative;
}

.skills__content {
  gap: 3em;
  display: grid;
}

.skills .skill-pills__container {
  flex-wrap: wrap;
  gap: .9em;
  display: flex;
}

.certificates {
  gap: 1.6em;
  display: grid;
}

.certificates ul {
  margin: 0;
  padding: 0 2em;
  list-style-type: circle;
}

.certificates li:not(li:first-of-type) {
  margin-block: 1em;
}

.certificates .link {
  font-size: var(--fs-200);
  display: inline-flex;
}

.certificates__other__title {
  margin-block-end: 1em;
}

.footer {
  border-top: 1px solid var(--clr-gray-100);
}

.footer__contents {
  flex-direction: column;
  margin-top: auto;
  padding-block: 2em;
  display: flex;
}

@media (width >= 1000px) {
  .footer__contents {
    width: 70%;
    max-width: 1000px;
    margin-inline: auto;
  }
}

.footer__contents, .footer .footer__socials {
  gap: 2em;
}

@media (width >= 800px) {
  .footer__contents, .footer .footer__socials {
    gap: 3em;
  }
}

.footer__links {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

@media (width >= 800px) {
  .footer__links {
    width: 60%;
    margin-inline: auto;
  }
}

.footer__links .link {
  color: var(--clr-text-300);
  font-weight: 400;
  font-size: var(--fs-300);
  transition: color var(--transition-speed-normal) ease;
}

.footer__links .link:hover, .footer__links .link:focus {
  color: #fff;
}

.footer__socials {
  text-align: center;
  flex-direction: column;
  display: flex;
}

@media (width >= 800px) {
  .footer__socials {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.footer .socials {
  border: none;
  margin: 0;
  padding: 0;
}

@media (width >= 800px) {
  .footer .socials {
    width: 50%;
    border: 1px solid var(--clr-gray-100);
    border-radius: var(--border-radius-400);
    padding: .5em 0;
  }
}

/*# sourceMappingURL=index.047cf31b.css.map */
