:root {
	// Light theme
	--clr-body-background: hsl(240, 11%, 99%);
	// --clr-title-text: hsl(240, 3%, 12%); // Darker OLD COLOR*
	--clr-text-200: hsl(240, 5%, 55%);
	--clr-text-300: hsl(240, 5%, 35%);
	--clr-text-400: hsl(240, 5%, 21%);
	--clr-title-text: var(--clr-text-300);
	--clr-about__text: var(--clr-text-400);

	--clr-gray-50: hsl(0, 0%, 83%); // d4d4d4
	--clr-gray-100: hsl(0, 0%, 84%); //d6d6d6
	--clr-gray-200: hsl(0, 0%, 64%);
	--clr-gray-300: var(--clr-text-300);

	// --clr-primary: hsl(196, 52%, 48%);
	--clr-primary: hsl(196, 52%, 60%);
	--clr-secondary: hsl(196, 52%, 50%);
	--clr-nav: rgba(235, 240, 251, 0.75);
	--clr-nav--scrolled: rgba(0, 0, 0, 0.13);
	--clr-nav--border: var(--clr-gray-50);
	--clr-plain-bg: hsl(0, 0%, 100%);
	--clr-card-bg: rgba(235, 240, 251, 0.7);
	--clr-card-title: var(--clr-gray-300);
	--clr-card-title--hover: var(--clr-text-400);
	--clr-card-body: var(--clr-text-200);
	--clr-section-background-text: hsla(0, 0%, 83%, 0.4);

	// --clr-accent: #f5a17a; // Medyo orange
	--clr-accent: hsl(43, 92%, 58%);

	// Shapes color
	--clr-shape-1: linear-gradient(90deg, hsla(217, 100%, 50%, 0.04) 0%, hsla(186, 100%, 69%, 0.04) 100%);
	--clr-shape-2: linear-gradient(45deg, hsla(296, 44%, 93%, 0.3) 45%, hsla(235, 88%, 69%, 0.3) 100%);
	--clr-shape-3: hsla(139, 60%, 93%, 0.5);
	--clr-shape-4: linear-gradient(45deg, hsla(208, 76%, 55%, 0.2) 0%, hsla(162, 82%, 50%, 0.2) 100%);
	--clr-shape-5: linear-gradient(45deg, hsla(302, 82%, 76%, 0.2) 0%, hsla(31, 90%, 76%, 0.2) 100%);
	--clr-shape-6: linear-gradient(45deg, hsla(33, 100%, 53%, 0.2) 0%, hsla(58, 100%, 68%, 0.2) 100%);
}

.dark-mode {
	//  ***************************************
	// Dark theme
	--clr-body-background: hsl(219, 44%, 8%);
	--clr-nav: hsla(219, 46%, 11%, 0.75);
	--clr-nav--scrolled: rgba(255, 255, 255, 0.13);
	--clr-nav--border: hsl(217, 32%, 19%);
	--clr-plain-bg: hsl(217, 45%, 18%);
	--clr-card-bg: hsla(219, 46%, 11%, 0.75);
	--clr-title-text: hsl(225, 69%, 88%);
	--clr-text-200: hsl(55, 5%, 45%);
	--clr-text-300: hsl(60, 5%, 65%);
	--clr-text-400: hsl(60, 4%, 79%);
	--clr-card-title: hsl(60, 5%, 90%);
	--clr-card-title--hover: hsl(60, 4%, 25%);
	--clr-card-body: hsl(55, 5%, 90%);
	--clr-section-background-text: hsla(0, 0%, 83%, 0.08);
	--clr-about__text: var(--clr-text-400);
	// --clr-card-body: red;

	--clr-shape-1: hsla(21, 78%, 10%, 0.5);
	--clr-shape-2: linear-gradient(90deg, hsla(288, 86%, 25%, 0.5) 0%, hsla(176, 100%, 50%, 0.5) 100%);
	--clr-shape-3: linear-gradient(45deg, hsla(152, 100%, 50%, 0.3) 0%, hsla(186, 100%, 69%, 0.3) 100%);
	--clr-shape-4: linear-gradient(45deg, hsla(208, 76%, 55%, 0.2) 0%, hsla(162, 82%, 50%, 0.2) 100%);
	--clr-shape-5: linear-gradient(45deg, hsla(302, 82%, 76%, 0.2) 0%, hsla(31, 90%, 76%, 0.2) 100%);
	--clr-shape-6: linear-gradient(45deg, hsla(33, 100%, 53%, 0.1) 0%, hsla(58, 100%, 68%, 0.1) 100%);

	--clr-shape-shadow-1: hsla(202, 79%, 50%, 0.5);
	--clr-shape-shadow-2: hsla(298, 44%, 50%, 0.2);
	--clr-shape-shadow-3: hsla(139, 60%, 50%, 0.2);
	--clr-shape-shadow-4: hsla(60, 40%, 50%, 1);
	--clr-shape-shadow-6: hsla(202, 79%, 50%, 0.1);
}
