@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@700&display=swap');

//https://fonts.google.com/?query=jakarta

// <link rel="preconnect" href="https://fonts.googleapis.com">
// <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
// <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;700&display=swap" rel="stylesheet">

:root {
	// --fs-400:

	--ff-default: 'Manrope', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	--ff-title: 'Epilogue', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	--ff-section-bg-text: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	--fs-100: 0.625rem; //10px
	--fs-110: 0.875rem; //14px
	--fs-200: 1rem; //16px
	--fs-300: 1.125rem; //18px
	--fs-400: 1.25rem; //20px
	--fs-410: 1.375rem; //22px
	--fs-500: 1.625rem; //26px
	--fs-550: 2.188rem; //35px
	--fs-600: 2.5rem; // 40px
	--fs-700: 3.125rem; //50px
	--fs-800: 3.75rem; //60px
	--fs-900: 5rem; // 80px
}
