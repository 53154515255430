@import 'animations';
@import 'breakpoints';
@import 'colors';
@import 'fonts';
@import 'resets';
@import 'utils';

:root {
	--border-radius-100: 0.188rem; //3px
	--border-radius-200: 0.5rem; //8px
	--border-radius-300: 0.625rem; //10px
	--border-radius-400: 1.25rem; //20px
	--border-radius-500: 1.875rem; //30px
	--border-radius-600: 3.125rem; //50px

	--transition-speed-normal: 500ms;
	--transition-timing-function-cubic: cubic-bezier(0.55, 0, 0.1, 1);
	--animation-speed-slow: 1600ms;
}

*,
*::before,
*::after {
	// outline: 1px dashed blue;
}

:focus-visible {
	border: 2px dashed var(--clr-accent);
	outline: 1px dashed var(--clr-accent);
	// outline: none;
}

// Scroll bar

/* width */
::-webkit-scrollbar {
	width: var(--fs-100);
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
	border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background-color: var(--clr-primary);
	border-radius: var(--border-radius-300);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: var(--clr-secondary);
}

body {
	font-family: var(--ff-default);
	font-weight: 400;
	color: var(--clr-text-300);
	background-color: var(--clr-body-background);
	// width: 100%;
	min-height: 100vh;
	transition: background-color var(--transition-speed-normal) ease,
		color var(--transition-speed-normal) ease;
	// height: 100%;
}

.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;
	margin-block: 1em;
	border: 0.5px solid var(--clr-nav--border);
	border-radius: var(--border-radius-600);

	// For positioning the navbar fixed
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);

	// This width is matched with the container--main
	width: 90%;
	max-width: 1200px;
	height: max-content;
	z-index: 4;

	// For bluring the navbar
	background-color: var(--clr-nav);
	backdrop-filter: blur(10px) saturate(1.4);
	// For scrolled class
	transition: box-shadow var(--transition-speed-normal) ease,
		background-color var(--transition-speed-normal) ease,
		border var(--transition-speed-normal) ease;

	@include mediaQuery(lrg) {
		// This width is matched with the container--main
		width: 80%;
		max-width: 1000px;
	}

	&.scrolled {
		box-shadow: 0 0 20px 2px var(--clr-nav--scrolled);
	}

	&__item__container--left {
		width: 100%;
		display: flex;
		align-items: center;

		@include mediaQuery(med) {
			width: 50%;
		}
	}

	// Actual button
	#hamburger--open {
		@include mediaQuery(med) {
			display: none;
		}
	}

	&__item__container--right[data-type='mobile'] {
		border: 1px dashed var(--clr-secondary);
		position: fixed;
		top: 0%;
		left: 50%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		width: 100%;
		min-height: 87vh;
		border-radius: var(--border-radius-400);

		// For bluring the navbar menu
		background-color: var(--clr-nav);
		z-index: 1;
		visibility: hidden;
		overflow: hidden;

		// This is to add blur on Google Chrome
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: var(--clr-nav);
			-webkit-backdrop-filter: blur(10px) saturate(1.4);
			backdrop-filter: blur(10px) saturate(1.4);
			z-index: -1;
		}

		@include mediaQuery(med) {
			display: none;
		}

		.hamburger--close-container {
			padding: 1em;
			display: flex;
			justify-content: flex-end;
			// This should match the nav. Para ma align

			@include mediaQuery(med) {
				display: none;
			}
		}

		// Actual button
		#hamburger--close {
			@include mediaQuery(med) {
				display: none;
			}
		}

		.close-icon {
			width: 40px;
			height: 40px;
		}

		.nav__list {
			list-style: none;
			margin: 0;
			height: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 0.8em;
			padding: 2em;
		}
	}

	&__item__container--right[data-type='desktop'] {
		z-index: 1;
		display: none;

		@include mediaQuery(med) {
			display: block;
			width: 50%;
		}

		.nav__list {
			list-style: none;
			padding: 0;
			margin: 0;
			height: auto;

			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 0.8em;
		}
	}

	&__link {
		font-size: var(--fs-600);
		font-weight: 600;
		text-decoration: none;
		color: var(--clr-text-400);
		// display: inline-block;
		display: block;
		transition: color var(--transition-speed-normal) ease;

		// clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

		@include mediaQuery(med) {
			font-size: var(--fs-300);
			font-weight: 500;
			border-radius: var(--border-radius-100);
		}
	}

	.dark-mode-icon,
	.light-mode-icon,
	.hamburger-icon,
	.close-icon {
		fill: var(--clr-primary);
	}

	.dark-mode-button {
		&[data-type='mobile'] {
			@include mediaQuery(med) {
				display: none;
			}
		}

		&[data-type='desktop'] {
			display: none;
			@include mediaQuery(med) {
				// display: inline-block;
				display: block;
				// width: 24px;
				// height: 34px;
			}
		}
	}

	.dark-mode-button[data-type='mobile'],
	.dark-mode-button[data-type='desktop'] {
		width: 30px;
		height: 30px;
		overflow: hidden;

		.dark-mode-icon--container,
		.light-mode-icon--container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: transform var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1);
		}

		.dark-mode-icon--container {
			transform: translate(0, -100%);
		}
	}
}

.hero {
	display: grid;
	align-items: center;
	gap: 1em;
	margin-block-start: 13vh;
	min-height: 87vh;
	min-height: 87dvh;
	overflow: hidden;
	position: relative;
	// border: 1px solid red;

	@include mediaQuery(med) {
		min-height: 100vh;
		min-height: 100dvh;
		margin-block-start: 0vh;
		grid-template-columns: repeat(2, 1fr);

		// Addition
		grid-template-rows: 1fr;
	}

	&__left {
		text-align: center;
		// border: 1px solid red;
		padding-inline: 1em;

		@include mediaQuery(sm) {
			padding-inline: 3em;
		}

		@include mediaQuery(med) {
			display: flex;
			flex-direction: column;
			text-align: initial;
			padding-inline: 0em;
		}

		.hero__greet {
			color: var(--clr-primary);
			font-size: var(--fs-400);
			font-weight: 500;
			margin-block-end: 1em;
		}

		.hero__title {
			font-family: var(--ff-title);
			color: var(--clr-title-text);
			font-size: var(--fs-600);
			line-height: 1.1;
			transition: color var(--transition-speed-normal) ease;

			@include mediaQuery(sm) {
				font-size: var(--fs-600);
			}

			@include mediaQuery(med) {
				font-size: var(--fs-700);
			}
		}

		.hand-wave {
			display: inline-block;
			animation: handWaveAnimation 2.5s infinite;
			transform-origin: 70% 70%;
		}

		.hero__subtitle {
			color: var(--clr-text-300);
			font-size: var(--fs-300);
			margin-block-start: 1em;
			transition: color var(--transition-speed-normal) ease;

			@include mediaQuery(sm) {
				font-size: var(--fs-300);
			}
		}

		&--upper {
			margin-block-start: 3em;
			// border: 1px dashed blue;

			@include mediaQuery(med) {
				min-height: 300px;
			}

			@include mediaQuery(med) {
				margin-block-start: 0em;
			}
		}

		&--lower {
			// border: 1px dashed red;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 1em;
			margin-block: 2em;

			@include mediaQuery(med) {
				flex-direction: row;
				margin-block-start: 2.2em;
			}

			@include mediaQuery(lrg) {
				flex-direction: row;
				margin-block-start: 2em;
			}
		}

		// I want to style the button inside hero differently

		// Hire me button
		.btn {
			width: 50%;
			// font-size: var(--fs-400);
			padding: 0.5em 0.5em;

			@include mediaQuery(med) {
				padding: 0.3em 1.3em;
				width: max-content;
			}
		}

		// About me link, I want to style it differently

		.btn,
		.link--lrg {
			font-size: var(--fs-300);
			padding: 0.5em 0.5em;

			@include mediaQuery(med) {
				padding: 0.3em 1.3em;
				width: max-content;
			}
			@include mediaQuery(lrg) {
				padding: 0.5em 1.4em;
				font-size: var(--fs-400);
			}
		}
	}

	&__right {
		// border: 1px solid red;

		&--upper {
			@include mediaQuery(med) {
				margin-block-start: 3em;
			}
			@include mediaQuery(lrg) {
				margin-block-start: 0em;
			}
		}

		.hero__img-box {
			isolation: isolate;
			position: relative;

			border: 1px solid var(--clr-gray-200);
			border-radius: var(--border-radius-400);
			max-width: 300px;
			margin-inline: auto;
			overflow: hidden;

			@include mediaQuery(lrg) {
				// max-width: 380px;
			}

			.hero__bg {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				transform: scale(1.05);
				z-index: -1;
				pointer-events: none;
			}

			.hero__img {
				transform: scale(1.3) translate(1%, 6%);

				// display: none;
			}
		}

		.socials {
			margin-inline: auto;
			width: 100%;

			@include mediaQuery(sm) {
				// width: 70%;
				width: 65%;
			}

			@include mediaQuery(med) {
				width: 90%;
				// width: 10%;
			}

			@include mediaQuery(lrg) {
				width: 80%;
			}
		}
	}

	&__bottom {
		grid-column: 1 / -1;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0;

		@include mediaQuery(med) {
			position: absolute;
			bottom: 5%;
			left: 0;
			gap: 0.2em;
		}

		&--text p {
			font-family: var(--ff-title);
			font-weight: 500;
			font-size: var(--fs-200);
			color: var(--clr-text-300);
			text-transform: uppercase;
			letter-spacing: 4;
		}

		.mouse-svg {
			width: 70px;
			height: 70px;
			fill: none;

			& #body {
				stroke: var(--clr-text-300);
			}

			& #scroll-wheel {
				fill: var(--clr-text-300);
				// fill: var(--clr-primary);
			}
		}
	}
}

section:not(.section__hero):not(.about) {
	margin-block: 5em;
	// border: 3px solid violet;

	@include mediaQuery(lrg) {
		// I used this to contain or to control the section background text
		width: 100%;
		max-width: 1000px;
		margin-inline: auto;
	}

	&:nth-of-type(2) {
		margin-block-start: 0em;
	}
}

.section__hero {
	overflow: hidden;
	position: relative;
}

.section {
	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-block-end: 1em;
	}

	&__title {
		color: var(--clr-primary);
	}

	&__title--normal {
		@include mediaQuery(med) {
			font-size: var(--fs-550);
		}

		@include mediaQuery(lrg) {
			font-size: var(--fs-550);
		}
	}

	&__title--sm {
		@include mediaQuery(lrg) {
			font-size: var(--fs-300);
		}
	}

	&__background-text {
		position: absolute;
		transform: translate(-1%, -5%);
		font-family: var(--ff-section-bg-text);
		color: var(--clr-section-background-text);
		font-size: var(--fs-900);
		transition: color var(--transition-speed-normal) ease;
	}
}

.about {
	// border: 1px solid red;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	isolation: isolate;
	z-index: 5;
	transition: background-color var(--transition-speed-normal) ease,
		color var(--transition-speed-normal) ease;

	&__intro {
		isolation: isolate;
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}

	.greeting--container {
		width: 100%;

		align-self: center;
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}

	.greeting {
		font-family: var(--ff-title);
		font-size: var(--fs-600);
		color: white;
		text-align: center;
	}

	.circle__container {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
		align-self: center;
		z-index: -1;

		.circle {
			width: 200px;
			height: 200px;
			margin-inline: auto;
			border-radius: 50%;
			transform: scale(1);
			background: radial-gradient(circle, hsla(0, 0%, 0%, 1) 0%, hsla(250, 46%, 49%, 1) 100%);
			// z-index: -1;

			@include mediaQuery(sm) {
				width: 400px;
				height: 400px;
			}
			@include mediaQuery(med) {
				width: 500px;
				height: 500px;
			}
		}
	}

	&__main {
		padding: 1em;
		background-color: #000;
		transition: background-color var(--transition-speed-normal) ease,
			color var(--transition-speed-normal) ease;

		@include mediaQuery(lrg) {
			display: flex;
			justify-content: space-between;
			gap: 3em;
		}
	}

	&__img--container {
		// margin-block-end: 2em;
		margin-block: 3em;

		.about__img {
			border-radius: var(--border-radius-300);
			object-fit: cover;
			margin-inline: auto;
			max-width: 400px;
			width: 100%;
			pointer-events: none;

			@include mediaQuery(med) {
				max-width: 500px;
			}

			@include mediaQuery(lrg) {
				max-width: 600px;
			}
		}
	}

	&__text--container {
		transition: color var(--transition-speed-normal) ease;
		width: 90%;
		margin-inline: auto;

		@include mediaQuery(lrg) {
			margin-block: 3em;
		}

		ul li:not(:last-child) {
			margin-block-end: 1.3em;

			@include mediaQuery(lrg) {
				margin-block-end: 1em;
			}
		}
	}

	.work-experience,
	.about-me {
		font-size: var(--fs-300);
		line-height: 2;
		margin: 1em 0;
	}

	.work-experience {
		margin-block-end: 1em;
	}

	.highlight {
		background: linear-gradient(45deg, hsla(208, 76%, 55%, 1) 0%, hsla(162, 82%, 50%, 1) 100%);

		background-size: 0% 100%;
		background-repeat: no-repeat;

		border-radius: var(--border-radius-100);
		transition: background-size var(--transition-speed-normal)
				var(--transition-timing-function-cubic),
			color var(--transition-speed-normal) var(--transition-timing-function-cubic),
			font-weight var(--transition-speed-normal) var(--transition-timing-function-cubic);

		&.active {
			background-size: 100% 100%;
			color: black;
		}
	}
}

.projects {
	position: relative;

	.btn {
		// Resume
		color: white;
		text-decoration: none;
		font-size: var(--fs-200);
		border-radius: var(--border-radius-200);
		padding: 0.5em 1em;

		display: flex;
		justify-content: space-evenly;
		align-items: center;
		gap: 0.7em;
		z-index: 3;
	}

	.pdf-icon {
		fill: white;
		transition: fill var(--transition-speed-normal) ease;
	}

	&__cards-container {
		margin-block-end: 2em;
		display: flex;
		flex-direction: column;
		gap: 2em;
	}

	.card {
		background-color: var(--clr-card-bg);
		backdrop-filter: blur(10px) saturate(1.4);
		padding: 2em;
		border-radius: var(--border-radius-300);
		transition: background-color var(--transition-speed-normal) ease;

		// Go to utils for work-in-progress

		&__sm {
			background-color: transparent;
			padding: 0;
		}

		&__title {
			color: var(--clr-card-title);
			transition: color var(--transition-speed-normal) ease;

			@include mediaQuery(lrg) {
				font-size: var(--fs-400);
			}
		}

		&__body {
			color: var(--clr-card-body);
			margin-block-start: 1em;
			margin-block-end: 2em;
			line-height: 1.8;
			font-weight: 200;
			transition: color var(--transition-speed-normal) ease;

			@include mediaQuery(lrg) {
				font-size: var(--fs-200);
			}
		}

		&__tech-used {
			display: flex;
			gap: 0.9em;
			flex-wrap: wrap;
			width: 100%;

			@include mediaQuery(sm) {
				width: 90%;
			}

			.skill {
				border: 1px solid hsla(0, 0%, 84%, 0.6);
				padding: 0.2em 0.8em;

				&__icon {
					width: 18px;
					height: 28px;
				}

				&__text {
					font-size: var(--fs-110);
					color: var(--clr-primary);
				}
			}
		}

		&__link {
			font-size: var(--fs-200);
			display: flex;
			justify-content: flex-end;
			margin-block-start: 1em;
		}
	}

	.card--transparent {
		margin-block-end: 2em;
		// border: 1px dashed red;
	}

	.more-projects {
		display: flex;
		flex-direction: column;
		gap: 1em;

		& ul {
			list-style-type: circle;
			margin: 0;
			padding: 0em 2em;
		}

		& li:not(li:first-of-type) {
			margin-block: 1em;
		}

		// Kaya ko to sinet para lang dun sa list sa loob ng more projects
		& .link {
			display: inline-flex;
		}
	}

	.link {
		font-size: var(--fs-200);

		&[data-name='frontendmentor'] {
			top: 8px;
		}
	}
}

// Skills
.skills {
	// min-height: 100vh;
	position: relative;

	&__content {
		display: grid;
		gap: 3em;
	}

	.skill-pills__container {
		// border: 1px dashed green;
		display: flex;
		gap: 0.9em;
		flex-wrap: wrap;
	}

	// Goto utils for each skill pill
}

// Certificates
.certificates {
	display: grid;
	gap: 1.6em;

	& ul {
		list-style-type: circle;
		margin: 0;
		padding: 0 2em;
	}

	& li:not(li:first-of-type) {
		margin-block: 1em;
	}

	.link {
		display: inline-flex;
		font-size: var(--fs-200);
	}

	&__other {
		&__title {
			margin-block-end: 1em;
		}
		// border: 1px dashed green;
	}
}

// Footer
.footer {
	border-top: 1px solid var(--clr-gray-100);

	&__contents {
		padding-block: 2em;
		display: flex;
		flex-direction: column;
		margin-top: auto;
		// border: 1px solid yellow;

		@include mediaQuery(lrg) {
			width: 70%;
			max-width: 1000px;
			margin-inline: auto;
		}
	}

	&__contents,
	.footer__socials {
		//.footer and .footer__socials
		gap: 2em;

		@include mediaQuery(med) {
			gap: 3em;
		}
	}

	&__links {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		// border: 1px solid red;

		@include mediaQuery(med) {
			width: 60%;
			margin-inline: auto;
		}

		.link {
			color: var(--clr-text-300);
			font-weight: 400;
			font-size: var(--fs-300);
			transition: color var(--transition-speed-normal) ease;

			&:hover {
				color: #fff;
			}

			&:focus {
				color: #fff;
			}
		}
	}

	&__socials {
		text-align: center;
		display: flex;
		flex-direction: column;

		@include mediaQuery(med) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	.socials {
		border: none;
		margin: 0;
		padding: 0;

		@include mediaQuery(med) {
			width: 50%;
			padding: 0.5em 0;
			border: 1px solid var(--clr-gray-100);
			border-radius: var(--border-radius-400);
		}
	}
}
