.container {
	&--main {
		width: 90%;
		max-width: 1200px;
		margin-inline: auto;
		position: relative;
		// overflow: hidden;
		// border: 1px dashed red;

		@include mediaQuery(lrg) {
			width: 80%;
			max-width: 1000px;
		}
	}

	&--inner {
		width: 86%;
		max-width: 800px;
		margin-inline: auto;
		// overflow: hidden;
		// border: 2px dashed green;

		@include mediaQuery(med) {
			width: 100%;
			max-width: 1000px;
		}
	}
}

.btn {
	border: none;
	background: none;
	cursor: pointer;
	text-decoration: none;
	position: relative;

	&--solid {
		color: #fff;
		font-weight: 500;
		font-size: var(--fs-300);
		padding: 1em 0.75em;
		border-radius: var(--border-radius-200);
		background-color: var(--clr-primary);

		// Mga nadagdag
		position: relative;
		overflow: hidden;
		transition: background-color var(--transition-speed-normal) 100ms
			cubic-bezier(0.55, 0, 0.1, 1);
		&:focus,
		&:hover {
			background-color: var(--clr-accent);
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			border-radius: 50%;
			opacity: 0.8;
			pointer-events: none;
			z-index: -1;
		}

		&::before {
			bottom: 0;
			left: 100%;
			width: 100%;
			height: 100%;
			transform: translateX(-50%) translateY(100%) rotate(-25deg);
			background-color: rgba(0, 0, 0, 0.1);
			transition: left var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1),
				transform var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1),
				background-color var(--transition-speed-normal) 100ms cubic-bezier(0.55, 0, 0.1, 1);
		}

		&:focus::before,
		&:hover::before {
			left: 0;
			transform: translateX(0) translateY(0);
			background-color: var(--clr-accent);
			scale: 1.4;
		}

		&::after {
			bottom: 0;
			left: 100%;
			width: 140%;
			height: 100%;
			transform: translateX(-50%) translateY(140%) rotate(-25deg);
			background-color: var(--clr-accent);
			transition: left var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1),
				transform var(--transition-speed-normal) 25ms cubic-bezier(0.55, 0, 0.1, 1);
		}

		&:focus::after,
		&:hover::after {
			left: 0%;
			transform: translateX(0%) translateY(0%);
			scale: 2;
		}
	}

	&--icon-only {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.logo {
	font-weight: 600;
	font-size: var(--fs-400);
	text-decoration: none;
	color: var(--clr-primary);
	display: inline-block;
	padding: 0 0.5em;

	transition: background-color var(--transition-speed-normal) ease;

	// Bagong dagdag
	border-radius: var(--border-radius-200);
	transition: color var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1);

	position: relative;
	isolation: isolate;
	overflow: hidden;

	&:focus,
	&:hover {
		color: white;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		opacity: 0.8;
		pointer-events: none;
		z-index: -1;
	}

	&::before {
		inset: 0;
		width: 100%;
		height: 100%;
		transform: translateY(100%);
		background-color: rgba(0, 0, 0, 0.1);
		transition: transform var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1),
			background-color var(--transition-speed-normal) 100ms cubic-bezier(0.55, 0, 0.1, 1);
	}
	&:focus::before,
	&:hover::before {
		transform: translateY(0);
		background-color: var(--clr-primary);
		scale: 1.4;
	}

	&::after {
		bottom: 0;
		left: 50%;
		width: 140%;
		height: 100%;
		transform: translateX(-50%) translateY(180%);
		background-color: var(--clr-primary);
		transition: transform var(--transition-speed-normal) 25ms cubic-bezier(0.55, 0, 0.1, 1);
	}
	&:focus::after,
	&:hover::after {
		transform: translateX(-50%) translateY(0%);
		scale: 1.6;
	}
}

.link {
	text-decoration: none;
	color: var(--clr-primary);
	font-weight: 500;
	font-size: var(--fs-400);

	// bagong dagdag
	display: inline-block;
	// display: inline-flex; // Testing muna
	padding: 0 0.5em;
	border-radius: var(--border-radius-200);
	transition: color var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1);

	position: relative;
	isolation: isolate;
	overflow: hidden;

	&:focus,
	&:hover {
		color: white;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		opacity: 0.8;
		pointer-events: none;
		z-index: -1;
	}

	&::before {
		inset: 0;
		width: 100%;
		height: 100%;
		transform: translateY(100%);
		background-color: rgba(0, 0, 0, 0.1);
		transition: transform var(--transition-speed-normal) cubic-bezier(0.55, 0, 0.1, 1),
			background-color var(--transition-speed-normal) 100ms cubic-bezier(0.55, 0, 0.1, 1);
	}
	&:focus::before,
	&:hover::before {
		transform: translateY(0);
		background-color: var(--clr-primary);
		scale: 1.4;
	}

	&::after {
		bottom: 0;
		left: 50%;
		width: 140%;
		height: 100%;
		transform: translateX(-50%) translateY(180%);
		background-color: var(--clr-primary);
		transition: transform var(--transition-speed-normal) 25ms cubic-bezier(0.55, 0, 0.1, 1);
	}
	&:focus::after,
	&:hover::after {
		transform: translateX(-50%) translateY(0%);
		scale: 1.6;
	}

	&--lrg {
		font-size: var(--fs-400);
	}
}

.socials {
	border: 1px solid var(--clr-gray-100);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	padding: 0.5em 1em;
	border-radius: var(--border-radius-400);

	margin-block: 1.3em;

	&__icon {
		fill: var(--clr-text-300);
		width: var(--fs-500);
		height: var(--fs-500);
		margin: 0;
		padding: 0;
		transition: fill var(--transition-speed-normal) ease;
	}

	&__icon[data-name='linkedin-square']:hover {
		fill: hsl(210, 89%, 40%);
	}
	&__icon[data-name='github']:hover {
		fill: hsl(264, 55%, 52%);
	}
	&__icon[data-name='gmail']:hover {
		fill: hsl(4, 81%, 56%);
	}
	&__icon[data-name='instagram']:hover {
		fill: hsl(336, 72%, 52%);
	}
}

body.dark-mode {
	.shape {
		-webkit-filter: blur(10px) saturate(1.3);
		filter: blur(10px) saturate(1.3);
	}
	.shape-1 {
		display: none;
	}

	.shape-2 {
		box-shadow: 0 0 50px 1px var(--clr-shape-shadow-2);
		top: 10%;
		right: 40%;
		transition: box-shadow var(--transition-speed-normal) ease;
	}

	.shape-3 {
		box-shadow: 0 0 50px 1px var(--clr-shape-shadow-3);
		display: unset;
		bottom: 30%;
		right: 10%;

		width: 160px;
		height: 160px;
		transition: box-shadow var(--transition-speed-normal) ease;

		@include mediaQuery(med) {
			width: 200px;
			height: 200px;
		}

		@include mediaQuery(xlrg) {
			right: 16%;
		}

		@include mediaQuery(xxlrg) {
			right: 20%;
		}
	}
}

.shape {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--fs-500);
	-webkit-filter: blur(10px);
	filter: blur(10px);
	// z-index: 999999999; // For testing
	pointer-events: none;

	width: 50px;
	height: 300px;
	border-radius: var(--border-radius-500);
}

.shape-1 {
	background: var(--clr-shape-1);
	top: 20%;
	left: 25%;
	height: 800px;
	width: 200px;
	transform: scale(1) rotate(-45deg);
	border-radius: var(--border-radius-600);
	transition: background var(--transition-speed-normal) ease;
}
.shape-2 {
	background: var(--clr-shape-2);
	top: 10%;
	right: 30%;
	width: 100px;
	height: 100px;
	transform: scale(1) rotate(-45deg);
	transition: background var(--transition-speed-normal) ease;
	// border-radius: 30% 70% 17% 83% / 48% 50% 50% 52%;

	@include mediaQuery(med) {
		top: 25%;
		right: 10%;
		width: 200px;
		height: 200px;
	}

	@include mediaQuery(lrg) {
		top: 20%;
		right: 10%;
		width: 200px;
		height: 200px;
	}

	@include mediaQuery(xlrg) {
		top: 10%;
		right: 20%;
		width: 200px;
		height: 200px;
	}

	@include mediaQuery(xxlrg) {
		top: 18%;
		right: 30%;
		width: 200px;
		height: 200px;
	}
}
.shape-3 {
	background: var(--clr-shape-3);
	bottom: 20%;
	right: 10%;
	width: 200px;
	height: 200px;
	transform: scale(1) rotate(45deg);
	// border-radius: 100% 0% 15% 85% / 48% 73% 27% 52%;
	display: none;
	transition: background var(--transition-speed-normal) ease;
}

.shape-4 {
	background: var(--clr-shape-4);
	top: 30%;
	right: 20%;
	width: 200px;
	height: 200px;
	transform: scale(1) rotate(45deg);
	transition: background var(--transition-speed-normal) ease;
	// display: none;
}

.shape-5 {
	background: var(--clr-shape-5);
	bottom: 0%;
	left: 20%;
	width: 100px;
	height: 100px;
	transform: scale(1) rotate(45deg);
	transition: background var(--transition-speed-normal) ease;
	// border-radius: 66% 34% 70% 30% / 30% 30% 70% 70%;
	// display: none;
}

.shape-6 {
	background: var(--clr-shape-6);
	bottom: 0%;
	right: 20%;
	width: 40px;
	height: 140px;
	transform: scale(1) rotate(45deg);
	transition: background var(--transition-speed-normal) ease;
	// border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;

	@include mediaQuery(med) {
		width: 100px;
		height: 240px;
	}
}

.spacer {
	width: 100%;
	height: 50vh;
	border: 5px solid red;

	&.scroll-down {
		z-index: 100;
	}
}

.projects {
	.card {
		&.work-in-progress {
			position: relative;

			&::after {
				content: 'Work in progress';
				color: var(--clr-text-400);
				font-weight: 700;
				position: absolute;
				right: 0;
				top: 0;
				background-color: var(--clr-plain-bg);
				width: max-content;
				height: max-content;
				padding: 0.8em 0.5em;
				border-radius: var(--border-radius-100);
				transition: color var(--transition-speed-normal) ease,
					background-color var(--transition-speed-normal) ease;
			}

			.card__link {
				cursor: not-allowed;
				opacity: 0.6;
				pointer-events: none;
			}
		}
	}
}

.skill {
	background-color: var(--clr-plain-bg);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	gap: 0.3em;
	padding: 0.4em 1em;
	border: 1px solid hsla(0, 0%, 84%, 1);
	border-radius: var(--border-radius-500);
	transition: transform var(--transition-speed-normal) ease,
		border var(--transition-speed-normal) ease,
		background-color var(--transition-speed-normal) ease;

	&:hover {
		transform: scale(1.02);
	}

	&:hover .skill__text {
		color: var(--clr-card-title--hover);
	}

	&:hover .skill__icon {
		fill: var(--clr-card-title--hover);
	}

	// Divs of each skill tablet
	&[data-name='reactjs']:hover {
		background-color: hsl(192, 95%, 95%);
		border: 1px solid hsl(192, 95%, 68%);
	}

	&[data-name='tailwindcss']:hover {
		background-color: hsl(189, 94%, 95%);
		border: 1px solid hsl(189, 94%, 43%);
	}

	&[data-name='styled-components']:hover {
		background-color: hsl(340, 60%, 95%);
		border: 1px solid hsl(340, 60%, 65%);
	}

	&[data-name='postgresql']:hover {
		background-color: hsl(225, 73%, 95%);
		border: 1px solid hsl(225, 73%, 57%);
	}

	&[data-name='html5']:hover {
		background-color: hsl(18, 88%, 95%);
		border: 1px solid hsl(18, 88%, 55%);
	}

	&[data-name='css3']:hover {
		background-color: hsl(222, 88%, 95%);
		border: 1px solid hsl(222, 88%, 45%);
	}

	&[data-name='sass']:hover {
		background-color: hsl(331, 50%, 95%);
		border: 1px solid hsl(331, 50%, 65%);
	}

	&[data-name='bootstrap']:hover {
		background-color: hsl(291, 95%, 95%);
		border: 1px solid hsl(260, 95%, 57%);
	}

	&[data-name='javascript']:hover {
		background-color: hsl(60, 100%, 95%);
		border: 1px solid hsla(60, 100%, 50%, 0.7);
	}

	&[data-name='nodejs']:hover {
		background-color: hsl(118, 40%, 95%);
		border: 1px solid hsl(119, 98%, 22%);
	}

	&[data-name='golang']:hover {
		background-color: hsl(192, 100%, 95%);
		border: 1px solid hsl(192, 100%, 42%);
	}

	&[data-name='mongodb']:hover {
		background-color: hsl(112, 49%, 95%);
		border: 1px solid hsl(162, 70%, 23%);
	}

	&[data-name='mongoose']:hover {
		background-color: hsl(0, 100%, 95%);
		border: 1px solid hsl(0, 100%, 27%);
	}

	&[data-name='expressjs']:hover {
		background-color: hsl(207, 100%, 95%);
		border: 1px solid hsl(207, 100%, 45%);
	}

	&[data-name='ejs']:hover {
		background-color: hsl(73, 49%, 95%);
		border: 1px solid hsl(73, 49%, 59%);
	}

	&[data-name='gsap3']:hover {
		background-color: hsl(94, 75%, 95%);
		border: 1px solid hsl(94, 75%, 38%);
	}

	&[data-name='git']:hover {
		background-color: hsl(9, 86%, 95%);
		border: 1px solid hsl(9, 86%, 57%);
	}

	&[data-name='figma']:hover {
		background-color: hsl(241, 100%, 95%);
		border: 1px solid hsl(241, 100%, 66%);
	}

	&[data-name='photoshop']:hover {
		background-color: hsl(207, 97%, 95%);
		border: 1px solid hsl(207, 97%, 11%);
	}

	&[data-name='googlesheets']:hover {
		background-color: hsl(136, 66%, 95%);
		border: 1px solid hsl(139, 70%, 30%);
	}

	&[data-name='batchScripting']:hover {
		background-color: hsl(206, 100%, 95%);
		border: 1px solid hsl(206, 100%, 41%);
	}

	// Each skill__icon
	&__icon {
		width: 23px;
		height: 33px;

		fill: var(--clr-card-title);
		transition: fill var(--transition-speed-normal) ease;

		&[data-name='reactjs']:hover {
			fill: hsl(192, 95%, 68%);
		}
		&[data-name='tailwindcss']:hover {
			fill: hsl(189, 94%, 43%);
		}
		&[data-name='styled-components']:hover {
			fill: hsl(340, 60%, 65%);
		}
		&[data-name='postgresql']:hover {
			fill: hsl(225, 73%, 57%);
		}
		&[data-name='html5']:hover {
			fill: hsl(18, 88%, 55%);
		}
		&[data-name='css3']:hover {
			fill: hsl(222, 88%, 45%);
		}
		&[data-name='sass']:hover {
			fill: hsl(331, 50%, 65%);
		}
		&[data-name='bootstrap']:hover {
			fill: hsl(260, 95%, 57%);
		}
		&[data-name='javascript']:hover {
			fill: hsl(60, 60%, 50%);
		}
		&[data-name='nodejs']:hover {
			fill: hsl(119, 98%, 22%);
		}
		&[data-name='golang']:hover {
			fill: hsl(192, 100%, 42%);
		}

		&[data-name='mongodb']:hover {
			fill: hsl(162, 70%, 23%);
		}

		&[data-name='expressjs']:hover {
			fill: hsl(207, 100%, 45%);
		}
		&[data-name='git']:hover {
			fill: hsl(9, 86%, 57%);
		}
		&[data-name='figma']:hover {
			fill: hsl(241, 100%, 66%);
		}
		&[data-name='mongoose']:hover {
			fill: hsl(0, 100%, 27%);
		}
		&[data-name='ejs']:hover {
			fill: hsl(73, 49%, 59%);
		}
		&[data-name='gsap3']:hover {
			fill: hsl(94, 75%, 38%);
		}
		&[data-name='photoshop'] {
			width: 20px;
			height: 30px;

			&:hover {
				fill: hsl(207, 97%, 11%);
			}
		}

		&[data-name='googlesheets'] {
			width: 20px;
			height: 30px;

			&:hover {
				fill: hsl(139, 70%, 30%);
			}
		}

		&[data-name='batchScripting'] {
			&:hover {
				fill: hsl(206, 100%, 41%);
			}
		}
	}

	&__text {
		font-weight: 500;
		color: var(--clr-card-title);
		transition: color var(--transition-speed-normal) ease;
	}
}
