.tada {
	&:hover {
		-webkit-animation: var(--animation-speed-slow) infinite ease tada;
		animation: var(--animation-speed-slow) infinite ease tada;
	}
}

.logo-spin {
	-webkit-animation: infinite 5s linear logo-spin;
	animation: infinite 5s linear logo-spin;
}

@-webkit-keyframes handWaveAnimation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(14deg);
		transform: rotate(14deg);
	}

	20% {
		-webkit-transform: rotate(-8deg);
		transform: rotate(-8deg);
	}

	30% {
		-webkit-transform: rotate(14deg);
		transform: rotate(14deg);
	}
	40% {
		-webkit-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}
	50% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	60% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@keyframes handWaveAnimation {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	10% {
		-webkit-transform: rotate(14deg);
		transform: rotate(14deg);
	}

	20% {
		-webkit-transform: rotate(-8deg);
		transform: rotate(-8deg);
	}

	30% {
		-webkit-transform: rotate(14deg);
		transform: rotate(14deg);
	}
	40% {
		-webkit-transform: rotate(-4deg);
		transform: rotate(-4deg);
	}
	50% {
		-webkit-transform: rotate(10deg);
		transform: rotate(10deg);
	}

	60% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
		transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
